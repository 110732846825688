import React from "react";

import BoutonLink from "../boutonLink";
import { VARIANT } from "../bouton";

import styles from "./vodDisponible.module.scss";

const VodDisponible = () => {
    const lienUrl = "https://www.telequebec.tv/comment-nous-regarder";

    return (
        <div data-component="VodDisponible" className={styles.me}>
            <div className={styles.container}>
                <h4>Disponible sur l&#39;appareil de votre choix</h4>
                <a href={lienUrl} target="_blank" rel="noreferrer" className={styles.items}>
                    <img height="30" src="/images/logo/apple-tv.svg" alt="Apple TV" />
                    <img height="30" src="/images/logo/android.svg" alt="Android" />
                    <img height="24" src="/images/logo/chromecast.svg" alt="Chromecast" />
                    <img height="22" src="/images/logo/roku.svg" alt="Roku" />
                    <img
                        height="30"
                        className={styles.amazonFireTV}
                        src="/images/logo/amazon-fire-tv.svg"
                        alt="Amazon Fire TV"
                    />
                    <img height="20" src="/images/logo/smart-tv.svg" alt="Samsung Smart TV" />
                    <img height="20" src="/images/logo/android-tv.svg" alt="Android TV" />
                </a>
                <BoutonLink
                    href={lienUrl}
                    rel="noreferrer"
                    target="_blank"
                    variant={VARIANT.PRIMARY}
                >
                    Comment nous regarder
                </BoutonLink>
            </div>
        </div>
    );
};

export default VodDisponible;
