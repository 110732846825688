import React from "react";
import AllEmissionsPage from "@/pages/emissions";
import { PagedItems } from "@/types/contenu";
import { Emission } from "@/types/emissions";

type AllEmissionsProps = {
    data: PagedItems<Emission>;
};

const AllEmissions = ({ data }: AllEmissionsProps) => <AllEmissionsPage emissions={data} />;

export const getServerSideProps = async () => {
    const api = process.env.SERVER_API_URL;

    try {
        const response = await fetch(`${api}/v1/emissions`);

        if (response.ok) {
            const json: PagedItems<Emission> = await response.json();
            return {
                props: {
                    data: json,
                },
            };
        }

        if (response.status === 404) {
            return {
                redirect: {
                    destination: "/404",
                    permanent: false,
                },
            };
        }
    } catch {
        return {
            redirect: {
                destination: "/500",
                permanent: false,
            },
        };
    }

    return {
        redirect: {
            destination: "/500",
            permanent: false,
        },
    };
};

export default AllEmissions;
