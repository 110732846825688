import React from "react";
import Link from "next/link";
import classNames from "classnames";

import { Emission } from "@/types/emissions";

import Image from "../../ui/image";
import { TYPE_CONTENU, TYPE_GABARIT } from "../../utils/enums";

import styles from "./emissionCarte.module.scss";

type PropTypes = {
    className?: string;
    item: Emission;
};

const EmissionCarte = ({ className = "", item }: PropTypes) => {
    const { type, nom, id, slug, images } = item;

    if (type !== TYPE_CONTENU.EMISSION) {
        return null;
    }

    return (
        <div
            data-component="EmissionCarte"
            className={classNames(styles.me, {
                [className]: className,
            })}
        >
            <Link href={`/emissions/${id}/${slug}`}>
                <span className={styles.image}>
                    <em>
                        <Image
                            images={images}
                            gabarits={[
                                TYPE_GABARIT.FORMAT_2X3,
                                TYPE_GABARIT.FORMAT_1X1,
                                TYPE_GABARIT.FORMAT_16X9,
                            ]}
                            alt={nom}
                            className={styles.bg}
                        />
                    </em>
                    <img
                        src="data:image/gif;base64,R0lGODlhAgADAIAAAP///wAAACH5BAEAAAEALAAAAAACAAMAAAICjF8AOw=="
                        alt=""
                        width="2"
                        height="3"
                        className={styles.fake}
                    />
                </span>
            </Link>
            <h4 className={styles.title}>
                <Link href={`/emissions/${id}/${slug}`}>{nom}</Link>
            </h4>
        </div>
    );
};

export default EmissionCarte;
