import React from "react";

import { NextSeo } from "next-seo";
import classNames from "classnames";
import { getSEONoFollow, getSEONoIndex } from "../../utils/nextjs/seo";
import { INFOLETTRE_INDEXES } from "../../configs/pub";

import Layout from "../../layout";

import EmissionCarte from "./emissionCarte";
import Infolettre from "../../ui/pub/infolettre";
import Breadcrumb from "../../ui/breadcrumb";
import VODDisponible from "../../ui/vodDisponible";

import styles from "./emissions.module.scss";
import { Emission } from "@/types/emissions";

type PropTypes = {
    emissions: {
        items: Emission[];
    };
};

const EmissionsPage = ({ emissions }: PropTypes) => {
    const pageTitle = "Émissions";

    return (
        <Layout mainBody={false}>
            <NextSeo nofollow={getSEONoFollow()} noindex={getSEONoIndex()} title={pageTitle} />

            <div>
                <div className="body page">
                    <Breadcrumb title={pageTitle} />
                    <div id="main-content" className="content">
                        <h1>{pageTitle}</h1>
                        <ul className={styles.items}>
                            {emissions?.items?.map((item, index) => {
                                if (index >= INFOLETTRE_INDEXES.EMISSIONS[0]) {
                                    return null;
                                }
                                return (
                                    <EmissionCarte
                                        item={item}
                                        className={styles.itemList}
                                        key={item.id}
                                    />
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className={styles.infolettreContent}>
                    <Infolettre isSmall />
                </div>
                <div className="body page">
                    <div className={classNames(styles.contentFooter, "content")}>
                        <ul className={styles.items}>
                            {emissions?.items?.map((item, index) => {
                                if (index < INFOLETTRE_INDEXES.EMISSIONS[0]) {
                                    return null;
                                }
                                return (
                                    <EmissionCarte
                                        item={item}
                                        className={styles.itemList}
                                        key={item.id}
                                    />
                                );
                            })}
                        </ul>
                        <VODDisponible />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EmissionsPage;
